<template>
  <div>
    <draggable
      class="list-group"
      :list="list"
      group="people"
      @input="move"
      :options="{ disabled: disabledMove }"
    >
      <div
        class="list-group-item"
        v-for="(element, index) in list"
        :key="element.Name"
      >
        <v-text-field
          dense
          hide-details
          filled
          :label="displayName(element)"
          disabled
        />
        <div class="list-group-item__actions" v-if="!hideEdit">
          <v-btn @click="edit(index)" icon>
            <m-icon icon="mdi-pencil"></m-icon>
          </v-btn>
        </div>
        <div class="list-group-item__actions" v-if="!hideEdit">
          <v-btn
            :disabled="
              element.IsBase &&
              ['Podpisyvaet_utverzhdaet'].includes(element.Name)
            "
            @click="del(index)"
            icon
          >
            <m-icon icon="mdi-close"></m-icon>
          </v-btn>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: { draggable },
  props: {
    list: Array,
    hideEdit: {
      type: Boolean,
      default: false,
    },
    disabledMove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    move() {},
    displayName(element) {
      if (element.DisplayName) {
        return element.DisplayName;
      }
      return element.Name;
    },
    edit(index) {
      this.$emit("edit", index);
    },
    del(index) {
      this.list.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.list-group {
  display: flex;
  flex-wrap: wrap;
  &-item {
    display: flex;
    width: 45%;
    height: 60px;
    margin: 5px 10px;
    cursor: move;
    &__actions {
      align-self: center;
    }
  }
}
</style>

<template>
  <v-layout>
    <base-detail-view
      :loading="loading"
      :title="
        dataSource.IsSample
          ? 'Образец документа'
          : this.type === 'create'
          ? 'Создание шаблона документа'
          : 'Шаблон документа'
      "
      :sub-title="dataSource.Archived ? 'В архиве' : ''"
      :isUnsaved="isUnsaved && valid"
      @onSave="
        save();
        $refs.rich.saveDocument();
      "
      @onRefresh="init"
      :showSave="!readonly"
    >
      <template #subTitle>
        <span v-if="dataSource.Archived" class="caption ml-5"> В архиве</span>
        <span
          v-if="dataSource.Published && dataSource.IsSample"
          class="caption ml-5"
        >
          {{ "Опубликован" }}</span
        >
      </template>
      <v-btn
        v-if="dataSource.Id > 0"
        slot="action"
        color="primary"
        dark
        @click="handleCreateDocument"
      >
        Создать документ
      </v-btn>
      <v-btn
        v-if="dataSource.Id > 0 && !readonlyEdit && !dataSource.IsSample"
        slot="action"
        @click="handleArchive"
        dark
        color="primary"
        class="ml-4"
        :title="dataSource.Archived ? 'Извлечь из архива' : 'Добавить в архив'"
        :outlined="!!dataSource.Archived"
        >{{
          dataSource.Archived ? "Извлечь из архива" : "Добавить в архив"
        }}</v-btn
      >
      <v-btn
        v-if="dataSource.Id > 0 && !dataSource.Published && dataSource.IsSample"
        slot="action"
        @click="handlePublish"
        dark
        color="primary"
        class="ml-4"
        :title="!dataSource.Published ? 'Опубликовать' : ''"
        :outlined="!!dataSource.Published"
        >{{
          !dataSource.Published ? "Опубликовать" : "Отменить публикацию"
        }}</v-btn
      >
      <v-form
        class="block__content"
        ref="form"
        v-model="valid"
        :readonly="readonly"
      >
        <v-container rows>
          <v-row>
            <v-col>
              <v-text-field
                v-model="dataSource.Name"
                label="Название"
                required
                :rules="[rules.required]"
                :clearable="!readonly"
                v-up-first-symbol
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <m-select
                v-model="dataSource.ActivitySection"
                :items="categories"
                item-text="Name"
                label="Раздел деятельности"
                return-object
                :clearable="!readonly"
              ></m-select>
            </v-col>
            <v-col>
              <m-autocomplete
                v-model="dataSource.DocumentType"
                label="Тип документа"
                item-text="Name"
                :items="types"
                :clearable="!readonly"
                return-object
              ></m-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <base-panel title="Карточка документа" class="mt-2" header-together>
        <template slot="action">
          <div v-show="!readonly && isExpanded">
            <template-card-fields
              class="mr-2"
              :fields="DefaultFields"
              :items="
                dataSource.Parameters
                  ? dataSource.Parameters.filter((e) => e.IsBase)
                  : []
              "
              :width="'60vw'"
              :height="'80vh'"
              @save="changeFields"
            />
            <v-btn color="primary" dark @click="openCardField(-1)">
              <m-icon icon="mdi-plus" left></m-icon>
              Создать поле
            </v-btn>
          </div>
        </template>
        <template-card-field
          v-model="cardFieldDialog"
          :index="cardFieldIndex"
          :formValue="cardField"
          @save="saveCardField"
          :fieldNames="
            dataSource.Parameters
              ? dataSource.Parameters.map((e) => e.Name)
              : []
          "
          :defaultFieldNames="DefaultFields.map((e) => e.Name)"
          :width="'60vw'"
          :height="'80vh'"
        />
        <v-form class="block__content" :readonly="readonly">
          <v-expand-transition>
            <div>
              <v-container class="pt-0 pb-0">
                <div style="min-height: 10px"></div>
                <div v-if="isExpanded && dataSource.Parameters">
                  <template-fields
                    :list="dataSource.Parameters"
                    :hide-edit="readonly"
                    :disabled-move="readonly"
                    @edit="openCardField($event)"
                  />
                </div>
              </v-container>
            </div>
          </v-expand-transition>
          <div
            class="d-flex justify-center align-center mt-0"
            :style="[
              { cursor: 'pointer' },
              { margin: !isExpanded ? '10px 0' : '-13.5px 0' },
            ]"
            @click="isExpanded = !isExpanded"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="primary white--text"
                  icon
                  style="width: 27px; height: 27px"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon :class="isExpanded ? 'rotate-icon' : ''">
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>
              <span>{{
                !isExpanded
                  ? "Показать карточку документа"
                  : "Скрыть карточку документа"
              }}</span>
            </v-tooltip>
            <span v-show="!isExpanded" class="primary--text ml-1">
              Показать карточку документа
            </span>
          </div>
        </v-form>
      </base-panel>

      <m-tabs v-model="tab" :slider-size="2" :with-route="false">
        <v-tab key="Content">Шаблон документа</v-tab>
        <v-tab key="Document">Созданные документы</v-tab>
      </m-tabs>

      <v-window :value="tab">
        <v-window-item>
          <rich-viewer
            class="document-template-edit__rich"
            :value="Template"
            :fields="dataSource.Parameters"
            :readonly="readonly"
            ref="rich"
            document-type="template"
            @documentChanged="documentChanged"
          />
        </v-window-item>
        <v-window-item>
          <list-document
            :value="dataSource.Documents"
            :show-add-in-table="false"
            class="mt-2"
          >
          </list-document>
        </v-window-item>
      </v-window>
    </base-detail-view>
  </v-layout>
</template>

<script>
import DocumentTemplateService from "../../services/document/DocumentTemplateService";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import {
  defaultDocumentTemplateField,
  defaultDocumentTemplate,
} from "../../data/defaultObjects";
import RichViewer from "../../components/rich/RichViewer.vue";
import { mapActions } from "vuex";
import Permissions from "../../data/permissions";
import TemplateFields from "./components/TemplateFields.vue";
import TemplateCardFields from "./components/TemplateCardFields.vue";
import TemplateCardField from "./components/TemplateCardField";
import BasePanel from "../../layouts/BasePanel.vue";
import MAutocomplete from "../../components/base/inputs/mAutocomplete.vue";
import { sortBy } from "@/utils/Functions";
import MSelect from "../../components/base/inputs/mSelect.vue";
import AuditIndicatorCategoryService from "../../services/AuditIndicatorCategoryService";

const DefaultFields = [
  {
    Name: "Data",
    Type: 5,
    DisplayName: "Дата",
    Order: 0,
    IsBase: true,
  },
  {
    Name: "Nomer",
    Type: 4,
    DisplayName: "Номер",
    Order: 0,
    IsBase: true,
  },
  {
    Name: "Naimenovanie",
    Type: 4,
    DisplayName: "Наименование",
    Order: 0,
    IsBase: true,
  },
  {
    Name: "Data_otmeny",
    Type: 5,
    DisplayName: "Дата отмены",
    Order: 0,
    IsBase: true,
  },
  {
    Name: "Nachalo_perioda_deystviya",
    Type: 5,
    DisplayName: "Начало периода действия",
    Order: 0,
    IsBase: true,
  },
  {
    Name: "Okonchanie_perioda_deystviya",
    Type: 5,
    DisplayName: "Окончание периода действия",
    Order: 0,
    IsBase: true,
  },
  {
    Name: "Avtory",
    Type: 11,
    DisplayName: "Авторы",
    Order: 0,
    IsBase: true,
  },
  {
    Name: "Soglasuyut",
    Type: 11,
    DisplayName: "Согласуют",
    Order: 0,
    IsBase: true,
  },
  {
    Name: "Podpisyvaet_utverzhdaet",
    Type: 11,
    DisplayName: "Подписывает/Утверждает",
    Order: 0,
    IsBase: true,
  },
  {
    Name: "Oznakomit",
    Type: 11,
    DisplayName: "Ознакомить",
    Order: 0,
    IsBase: true,
  },
  {
    Name: "Utverzhdayushchiy_dokument",
    Type: 0,
    DisplayName: "Утверждающий документ",
    Order: 0,
    IsBase: true,
  },
  {
    Name: "Iskhodnyy_dokument",
    Type: 0,
    DisplayName: "Исходный документ",
    Order: 0,
    IsBase: true,
  },
  {
    Name: "Versiya",
    Type: 4,
    DisplayName: "Версия",
    Order: 0,
    IsBase: true,
  },
  {
    Name: "Primechanie",
    Type: 4,
    DisplayName: "Примечание",
    Order: 0,
    IsBase: true,
  },
];
const GetDefaultField = (name) => DefaultFields.find((f) => f.Name === name);
const DefaultTemplateFields = [
  GetDefaultField("Naimenovanie"),
  GetDefaultField("Avtory"),
  GetDefaultField("Nomer"),
  GetDefaultField("Soglasuyut"),
  GetDefaultField("Data"),
  GetDefaultField("Oznakomit"),
  GetDefaultField("Versiya"),
  GetDefaultField("Podpisyvaet_utverzhdaet"),
  GetDefaultField("Primechanie"),
];

export default {
  name: "view-DocumentTemplateEdit",
  components: {
    listDocument: () => import("@/components/document/ListDocument.vue"),
    mTabs: () => import("@/components/base/tabs/mTabs"),
    MAutocomplete,
    BaseDetailView,
    RichViewer,
    TemplateFields,
    TemplateCardFields,
    TemplateCardField,
    BasePanel,
    MSelect,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      DefaultFields,
      tab: 0,
      types: [],
      categories: [],
      cardField: null,
      cardFieldIndex: null,
      cardFieldIsBase: false,
      cardFieldDialog: false,
      headers: [
        { text: "Название", value: "Name" },
        { text: "Тип", value: "Type" },
        { text: "Отображаемое значение", value: "" },
      ],
      isShowPanel: true,
      // Api
      apiService: DocumentTemplateService,
      getDefaultObject: this.getDefaultDocumentTemplate,
      getObjectText: (obj) => obj.Name,
      loading: true,
      valid: false,
      rules: {
        required: (value) => !!value || "Укажите значение.",
        typeRequired: (value) =>
          !!value ||
          this.dataSource.Parameters.findIndex((p) => p.Name === "Type") !==
            -1 ||
          "Укажите тип документа или добавьте поле в карточку документа.",
      },
      dataSource: this.getDefaultDocumentTemplate(),
      Template: null,
      isExpanded: false,
    };
  },
  computed: {
    readonlyEdit() {
      return (
        !this.hasPermission(Permissions.DocumentTemplatesEdit) ||
        this.dataSource?.IsSystem
      );
    },
    readonly() {
      return (
        this.dataSource.OnlyRead ||
        !!this.dataSource?.Archived ||
        !this.hasPermission(Permissions.DocumentSampleEdit) ||
        this.readonlyEdit
      );
    },
    pageType() {
      return this.currentRouter.params?.type;
    },
  },
  watch: {
    cardFieldDialog(value) {
      if (!value) this.cardField = null;
    },
  },
  async mounted() {
    await this.init();

    this.categories = sortBy(
      (await AuditIndicatorCategoryService.get()).data,
      "Name"
    );
    this.employees = await this.LOAD_EMPLOYEES();
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES"]),
    defaultDocumentTemplateField,
    getDefaultDocumentTemplate() {
      return defaultDocumentTemplate(this._.cloneDeep(DefaultTemplateFields));
    },
    handleCreateDocument() {
      this.$router.push({
        name: "LocalDocumentEdit",
        params: { objectId: 0 },
        query: {
          type: "create",
          templateId: this.dataSource.Id,
        },
      });
    },

    async handlePublish() {
      try {
        await DocumentTemplateService.publish([this.dataSource.Id]);
        this.dataSource.Published = true;
        this.originObject.Published = true;
      } catch (error) {
        console.log(error);
      }
    },
    async handleArchive() {
      const { data } = await DocumentTemplateService.archive(
        this.dataSource.Id
      );
      this.dataSource.Archived = data.Archived;
      this.originObject.Archived = data.Archived;
    },

    openCardField(index) {
      this.cardField =
        index === -1
          ? defaultDocumentTemplateField()
          : this.dataSource.Parameters[index];
      this.cardFieldIndex = index;
      this.cardFieldDialog = true;
    },
    saveCardField(e) {
      if (e.index === -1) this.dataSource.Parameters.push({ ...e.formValue });
      else this.$set(this.dataSource.Parameters, e.index, { ...e.formValue });
      this.cardField = null;
      this.cardFieldDialog = false;
    },
    changeFields(items) {
      // Удаление
      const its = this.dataSource.Parameters.filter(
        (e) => e.IsBase && !items.find((e2) => e2.Name === e.Name)
      );
      for (const item of its) {
        const i = this.dataSource.Parameters.findIndex(
          (e) => e.Name === item.Name
        );
        this.dataSource.Parameters.splice(i, 1);
      }

      // Добавление
      for (const item of items) {
        if (!this.dataSource.Parameters.find((e) => e.Name === item.Name)) {
          this.dataSource.Parameters.push({ ...item });
        }
      }

      this.$refs.form.validate();
    },
    afterEdit(val) {
      const elemIndex = this.dataSource.Parameters.findIndex(
        (e) => e.Id === val.Id
      );
      if (elemIndex === -1) {
        this.dataSource.Parameters.push(val);
      } else {
        this.dataSource.Parameters[elemIndex] = val;
      }
    },
    DataFromServerNormalize(data) {
      const { Template, ...d } = data;
      this.Template = Template;
      d.IsSample = !!(this.$route.params?.type === "sample");
      console.log(
        "data",
        d,
        this.$route.params?.type,
        !!this.$route.params?.type === "sample"
      );
      return d;
    },

    async init() {
      this.baseInit();

      const data = (
        await DocumentTemplateService.GetOrganizationDocumentTypes()
      ).data;

      this.types = sortBy(data, "Name");
      this.cardField = null;
    },

    async save() {
      this.loading = true;
      this.dataSource.Template = await this.$refs.rich.getDocumentContent();
      this.originObject.Template = this.dataSource.Template;

      for (let i = 0; i < this.dataSource.Parameters.length; i++) {
        this.dataSource.Parameters[i].Order = i;
      }
      this.baseSave();
    },
    documentChanged(val) {
      if (val.document.modified) {
        this.$set(this.dataSource, "isModified", true);
      } else {
        delete this.dataSource.isModified;
      }
    },
  },
};
</script>

<style lang="scss">
.document-template-edit__rich.mt-4 {
  margin-top: 8px !important;
}
.local-document-edit__help-panel {
  text-align: right;
}
.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
</style>

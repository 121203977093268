<template>
  <base-popup
    title="Параметр шаблона документа"
    @save="save"
    :icon="UtilGetIcon('view-DocumentTemplates')"
    v-model="dialog"
    :width="width"
    :height="height"
  >
    <v-form v-if="dialog" v-model="valid" ref="form">
      <v-text-field
        v-model="form.DisplayName"
        label="Название"
        :rules="[rules.required]"
        @change="displayChanged"
        v-up-first-symbol
      >
      </v-text-field>
      <v-text-field
        v-model="form.Name"
        :readonly="form.IsBase"
        label="Системное название"
        :rules="[rules.required, rules.nameIsFree, rules.nameIsSystem]"
        v-up-first-symbol
      >
      </v-text-field>
      <m-select
        v-model="form.Type"
        :readonly="form.IsBase"
        :clearable="false"
        :items="types"
        item-text="text"
        item-value="value"
        label="Тип значения"
        :rules="[rules.requiredForCustom]"
        @change="typeChanged"
      >
      </m-select>
      <m-combobox
        v-if="typeFormat"
        :value="form.Format"
        @update:search-input="form.Format = $event"
        :items="typeFormat.formats"
        label="Формат значения"
      ></m-combobox>
      <v-card v-if="typeFormat">
        <v-card-subtitle>Справка по формату значения</v-card-subtitle>
        <v-card-text>
          <v-textarea
            :value="typeFormat.help"
            :readonly="true"
            :rows="15"
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-form>
  </base-popup>
</template>
<script>
import MCombobox from "../../../components/base/inputs/mCombobox.vue";
import MSelect from "../../../components/base/inputs/mSelect.vue";
import BasePopup from "../../../layouts/BasePopup.vue";
import DataHelper from "../../../utils/DataHelper";
import { defaultDocumentTemplateField } from "../../../data/defaultObjects";

const TypeFormats = [
  {
    type: 5,
    formats: ["дд.ММ.гггг", "дд.ММ.гггг чч:сс"],
    help: `• д - день месяца как число от 1 до 31.
• дд - день месяца как число от 01 до 31, то есть с лидирующим 0.
• ддд - сокращенное название дня недели: Пн, Вт, Ср, Чт, Пт, Сб, Вс.
• дддд - полное название дня недели: Понедельник, Вторник и т.д.
• М - месяц как число от 1 до 12.
• ММ - месяц как число 01 до 12, то есть с лидирующим 0.
• МММ - сокращенное название месяца: Янв, Фев, Мар, Апр, Май, Июн, Июл, Авг, Сен, Окт, Ноя, Дек.
• ММММ - полное название месяца в род. падеже: января, февраля и т.д.
• гг - две последние цифры года.
• гггг - 4-значный год.
• ч - час в 12-часовом формате, например 4 (дня или ночи).
• чч - час в 12-часовом формате с лидирующим 0, например 04 (дня или ночи).
• Ч - час в 24-часовом формате, например 16 (дня) или 4 (ночи).
• ЧЧ - час в 24-часовом формате с лидирующим 0, например 16 (дня) или 4 (ночи).
• м - минуты.
• мм - минуты с лидирующим 0.
• с - секунды.
• сс - секунды с лидирующим 0.`,
  },
  {
    type: 11,
    formats: ["{ФИО}", "{ФИО}, {Должность}"],
    help: `• {ФИО} - ФИО в формате "Фамилия И.О."
• {Фамилия} - фамилия.
• {Имя} - имя.
• {Отчество} - отчество.
• {Ф} - первая буква фамилии с точкой.
• {И} - первая буква имени с точкой.
• {О} - первая буква отчества с точкой.
• {ДатаРождения} - дата рождения в формате по умолчанию.
• {ДатаРождения:дд.ММ.гггг} - дата рождения в указанном формате.
• {Должность} - название должности на текущем рабочем месте.
• {Подразделение} - название подразделения на текущем рабочем месте.`,
  },
  {
    type: 12,
    formats: ["{Название}", "{Адрес}"],
    help: `• {Название} - название
• {Адрес} - адрес`,
  },
];

export default {
  components: { BasePopup, MSelect, MCombobox },
  props: {
    value: Boolean,
    formValue: {
      type: Object,
      default: () => {},
    },
    index: Number,
    fieldNames: {
      type: Array,
      default: () => [],
    },
    defaultFieldNames: {
      type: Array,
      default: () => [],
    },
    width: String,
    height: String,
  },
  data() {
    return {
      valid: true,
      form: defaultDocumentTemplateField(),
      types: [
        { text: "Число", value: 1 },
        { text: "Десятичное Число", value: 2 },
        { text: "Логическое", value: 3 },
        { text: "Строка", value: 4 },
        { text: "Дата и время", value: 5 },
        { text: "Сотрудник", value: 11 },
        { text: "Подразделение", value: 12 },
      ],
      rules: {
        required: (value) => !!value || "Укажите значение.",
        requiredForCustom: (value) =>
          !!value || this.form.IsBase || "Укажите значение.",
        nameIsFree: (value) => {
          const arr1 = this.fieldNames.map((e) => e.toLowerCase());
          const arr2 = this.defaultFieldNames.map((e) => e.toLowerCase());
          const val = value?.toLowerCase();
          return (
            (arr1.indexOf(val) === -1 && arr2.indexOf(val) === -1) ||
            (this.index >= 0 && arr1.indexOf(val) === this.index) ||
            "Название уже занято."
          );
        },
        nameIsSystem: (value) => {
          return value && value.match(/^[a-zA-Z0-9_]+$/)
            ? true
            : "Название может состоять только из латинских букв и цифр. Пробелы и другие символы не допускаются";
        },
      },
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        if (val !== this.value) this.$emit("input", val);
      },
    },
    typeFormat() {
      return TypeFormats.find((f) => f.type === this.form?.Type);
    },
  },
  watch: {
    formValue: function (val) {
      if (this.$refs.form) this.$refs.form.reset();
      this.form = val ? { ...val } : defaultDocumentTemplateField();
    },
  },
  methods: {
    save() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.$emit("save", { index: this.index, formValue: this.form });
    },
    displayChanged(value) {
      if (value && !this.form.Name) {
        this.form.Name = DataHelper.translite(
          value.toLowerCase()
        ).toLowerCase();
        this.form.Name = this.form.Name.trim().replaceAll(" ", "_");
      }
    },
    typeChanged() {
      this.form.Format = null;
    },
  },
};
</script>
